.blockui-demo .p-panel p {
    line-height: 1.5;
    margin: 0;
}

.divSticky {
    position: -webkit-sticky;
    position: sticky;
    top: 55px;
    z-index: 2;
}

.btnSticky {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10px;
    z-index: 1;
}