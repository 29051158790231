/* .p-datatable .p-datatable-header {
    background: #f1f8ff;
    color: #393d41;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-inputtext{
    padding: 0.3rem !important
} */

/* transition */
/* .p-datatable .p-datatable-header {
    background: #f1f8ff;
}

.highlight {
    background-color: white;
    transition: all 2 ease;
    transition-property: background-color, color;
    -webkit-transition: all 2 ease;
    -webkit-transition-property:  background-color, color;
    animation: imganim 5s normal both !important;
}


@keyframes imganim {
    from {background:rgb(252, 218, 154);}
    to {background: #ffffff;} 
} */

.trunc {   
    width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
 }