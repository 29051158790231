/* .checkLabel {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
  }

.checkInput {
    width: 13px;
    height: 23px;
    padding: 0;
    margin:0;
    vertical-align: bottom;
    position: relative;
    top: 0px;
    *overflow: hidden;
} */

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.pi {
  font-family: 'primeicons';
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
