.topnav {
    overflow: hidden;
    background-color: #f1f1f1;
    display: flex
  }
  
  .topnav a {
    float: left;
    display: block;
    color: rgb(109, 109, 109);
    text-align: center;
    padding: 12px 14px;
    text-decoration: none;
    font-size: 15px;
    font-weight: lighter;
    border-bottom: 3px solid transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .topnav a:hover {
    border-bottom: 3px solid rgb(158, 158, 158);
  }
  
  .topnav a.active {
    color: black;
    font-weight: bold;
    border-bottom: 3px solid #f9826c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  