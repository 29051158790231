.neonShadow{
    height:50px;
    width:50px;
    border:none;
    border-radius:50px;
    transition:0.3s;
    /* background-color:rgba(156, 161, 160,0.3); */
    animation: glow 1s infinite ;
    transition:0.5s;
}

@keyframes glow{
    0%{
    box-shadow: 2px 2px 5px #E74C3C,-2px -2px 5px #E74C3C;}
    
    50%{
    box-shadow: 2px 2px 5px #2ECC71,-2px -2px 5px #2ECC71
    }
    100%{
    box-shadow: 2px 2px 5px #F1C40F,-2px -2px 5px #F1C40F
    }
}